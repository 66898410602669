// header: 
// category/date: 
// text: 
// text:

export const TPP_site_fonts = {
    "header": "TiemposHeadlineBold,serif",
    "category": "BentonSansMedium,serif",
    "summary": "BentonSansBook,serif",
    "byline": "-apple-system,\
    BlinkMacSystemFont,\
    Segoe UI,\
    Roboto,\
    Oxygen,\
    Ubuntu,\
    Cantarell,\
    Fira Sans,\
    Droid Sans,\
    Helvetica Neue,\
    sans-serif",
    "text": "BentonSansBook"
};
