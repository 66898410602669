import './ImageModal.css'

import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 800,
    width: "fit-content",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ImageModal = ({ imgTN, img, desc, title }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div
                className='modalImgThumbnail'
                onClick={handleOpen}
                style={{ display: 'inline' }}>
                {imgTN}
            </div>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='MUIBox'>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ margin: [2, 2] }}>
                            {title}
                        </Typography>
                        {img}
                        <Typography id="modal-modal-description" sx={{ margin: [1, 3] }}>
                            {desc}
                        </Typography>
                    </Box>
                </Modal >
            </div>
        </>
    );
}

export default ImageModal;
