import Draggable, { DraggableCore } from 'react-draggable'; // Both at the same time


export default function DraggableImage({ content = <p>text</p> }) {

    return (
        <Draggable
            className="draggable-box"
        >
            {content}
        </Draggable>
    )
}