import NavBar from './components/NavBar/NavBar';
import Home from './components/Sections/Home/Home';
import Footer from './components/Sections/Footer/Footer';
import './App.css';

import { useEffect, useState, createContext } from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import firebaseConfig from './firebaseConfig';

import BuyMeACoffeeWidget from './components/Modules/Externals/BuyMeACoffeeWidget';

// initialize firebase app
firebase.initializeApp(firebaseConfig);

function App() {

  useEffect(() => {
    document.title = 'The Pig Pencil'
  }, [])

  return (
    <Router>
      <div className="App">
        <NavBar />
        <Footer />
        <BuyMeACoffeeWidget />
      </div>
    </Router>
  );
}

export default App;
