import { Link } from "react-router-dom";
import { useContext } from "react";
import { DropdownContext } from "./DropdownMenuItem";

import './Dropdown.css';

const Dropdown = ({ submenus }) => {
    const { dropdownVisibility, setDropdownVisibility } = useContext(DropdownContext);
    return (
        <ul className={`dropdown ${dropdownVisibility ? "show" : ""}`}>
            {submenus.map((submenu, index) => (
                <Link to={submenu.url} className="submenu-link">
                    <li
                        className="menu-item submenu-item"
                        key={index}
                        onClick={() => {
                            setDropdownVisibility(false)
                        }}
                    // onMouseEnter={() => setCurrentHoveredTab(menu.title.toLowerCase())}
                    >
                        <span>
                            {submenu.title}{' '}
                        </span>
                    </li>
                </Link>
            ))}
        </ul>
    );
};

export default Dropdown;