import React, { useRef, useState, useEffect } from 'react'

import './ScrollDynamicStickyHeader.css'

export default function ScrollDynamicStickyHeader({
    content = <p>header text</p>,
    topPosition = "0vh",
    startingLeftPosition = 20,
    endLeftPostion = 1,
    startingFontSize = 'x-large',
    endingFontSize = 'medium'
}
) {
    const [leftPosition, setLeftPosition] = useState(startingLeftPosition);
    const [headerSize, setHeaderSize] = useState(startingFontSize);
    const headerRef = useRef(null);

    // update distance of element from top when scrolling
    useEffect(() => {
        const handleScroll = () => {
            // get distance of element from top
            const rect = headerRef.current.getBoundingClientRect();
            const distance = rect.top;
            // if distance == topPosition, start moving element to left & shrink
            if (distance == topPosition) {
                setLeftPosition(endLeftPostion);
                setHeaderSize(endingFontSize);
            }
            // reset position if scrolled up, & resize
            if (distance > topPosition) {
                setLeftPosition(startingLeftPosition);
                setHeaderSize(startingFontSize);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Run the effect only once on mount

    return (
        <h2
            className='sticky-header'
            ref={headerRef}
            style={{
                top: topPosition,
                left: `${leftPosition}%`,
                fontSize: headerSize,
                transition: 'left 0.6s, fontSize 0.6s'
            }}>
            {content}
        </h2>
    )
}
