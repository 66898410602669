import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <a className="footer-link" href="http://www.instagram.com/pigpencil/" target="_blank" rel="noreferrer">
                <img className="footer-icon" style={{position:'relative', top:'0.2em', height:'2.2em'}} src='\img\icons\IG_icon.png' alt='ig' />
            </a>
            <a className="footer-link" href="https://www.youtube.com/@ThePigPencil" target="_blank" rel="noreferrer">
                <img className="footer-icon" style={{position:'relative', top:'0.2em', left:'0.1em', height:'2.2em', paddingRight: '4px'}} src='\img\icons\youtube_icon.png' alt='youtube' />
            </a>
            <a className="footer-link" href="https://github.com/tylerwu2222" target="_blank" rel="noreferrer">
                <img className="footer-icon" style={{height:'2em'}} src='\img\icons\github_icon.png' alt='github' />
            </a>
            <a className="footer-link footer-link-right" href="mailto:thepigpencil@gmail.com">
                <img className="footer-icon" style={{ height:'1.8em', paddingLeft: '4px'}}src='\img\icons\mail_icon.png' alt='mail' />
            </a>
           
        </footer>
    )
}

export default Footer;