// import fontFamilies from '../../site_data/logo_fonts.json';

import './NavBar.css';
import './SubscribeButton.css';
// import '../../Fonts.css'

// google analytics
import ReactGA from "react-ga4";
import reportWebVitals from '../../../src/reportWebVitals.js';

// react
import { useEffect, useState, createContext } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";

// context
// import { appContext } from "../../App";

// navbar components & data
import MenuItems from "./MenuItems.js";
import menuItems from "../../site_data/navbar_menu_items.json";
import randomFontFamilies from '../../site_data/logo_font_names.json';

// sections
import Home from "../Sections/Home/Home.js";
import Data from "../Sections/Data/Data.js";
import Writing from "../Sections/Writing/Writing.js";
import Art from "../Sections/Art/Art.js";
import Cheatsheets from "../Sections/Learning/Cheatsheets.js";
import Tutorials from "../Sections/Learning/Tutorials.js";
import Projects from '../Sections/Projects/Projects.js';
import Me from "../Sections/People/Me.js";
import Collaborators from '../Sections/People/Collaborators.js';
import Subscribe from "../Sections/Subscribe/Subscribe.js";

// post templates
import ArtPage from "../PostTemplates/ArtPostTemplates/ArtPage.js";
import DataPage from "../PostTemplates/DataPostTemplates/DataPage.js";
import CheatsheetPage from "../PostTemplates/LearningPostTemplates/CheatsheetPage.js";
import TutorialPage from "../PostTemplates/LearningPostTemplates/TutorialPage.js";
import ProjectsPage from '../PostTemplates/ProjectsPostTemplates/ProjectsPage.js';
import WritingPage from '../PostTemplates/WritingPostTemplates/WritingPage.js';
// import MDXtoJSTemplate from '../PostTemplates/WritingPostTemplates/MDXtoJSTemplate.js';
// import PhotoEssay from "../PostTemplates/WritingPostTemplates/PhotoEssay/PhotoEssay.js";

// post data (+ individual posts)
import artPosts from '../../post_data/art_sections.json';
import dataPosts from '../../post_data/data_articles.json';
import writingPosts from '../../post_data/writing_articles.json';
import cheatSheetPosts from '../../post_data/cheatsheet_articles.json';
import tutorialPosts from '../../post_data/tutorial_articles.json';
import projectsPosts from '../../post_data/projects_articles.json';

// mobile
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { useWindowSize } from '../Functions/useWindowSize.js';

export const get_component_name = (name) => {
    let component_name = name.split("_"); // berk_nature --> [berk,nature]
    component_name = component_name.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(''); // [berk, nature] --> [Berk,Nature] --> BerkNature
    // console.log(name,component_name);
    return component_name
};

//Initialize GA4
ReactGA.initialize("G-H4SNP8DRZ6");

const SendAnalytics = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);

// menu item values
const menutTitles = menuItems.map(i => i.title);
const delay = 0; // no initial delay

// context for navbar shenanigans
export const hoveredTabContext = createContext();

// const useWindowSize = () => {
//     const [windowSize, setWindowSize] = useState({
//         width: window.innerWidth,
//         height: window.innerHeight,
//     });

//     useEffect(() => {
//         const handleResize = () => {
//             setWindowSize({
//                 width: window.innerWidth,
//                 height: window.innerHeight,
//             });
//         };

//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     return windowSize;
// };

const NavBar = ({ page }) => {

    const [menuVisible, setMenuVisible] = useState(false);
    const [currentHoveredTab, setCurrentHoveredTab] = useState('___');

    const [userHovered, setUserHovered] = useState(false);
    const [hoverTabInterval, setHoverTabInterval] = useState(null);

    const [logoFontFamily, setLogoFontFamily] = useState('Gloock');
    const [randomFontIndex, setRandomFontIndex] = useState(0);

    const { width } = useWindowSize();

    const isMobile = width <= 1024;

    // hide navbar menu when location changes
    const location = useLocation();
    useEffect(() => {
        // console.log('location changed', location);
        // console.log('menuVisible', menuVisible);
        if (menuVisible) {
            // console.log('hiding menu');
            setMenuVisible(false);
        }
    }, [location]);

    // cancel auto hovering when user hovers --> NOT WORKING
    // useEffect(() => {
    //     if (userHovered) {
    //         clearInterval(hoverTabInterval);
    //         // setHoverTabInterval(null); // Reset the interval ID in state
    //     }
    // }, [userHovered]);

    // auto select hovered tab after n seconds
    // useEffect(() => {
    //     console.log('set menu title')
    //     const startSettingTab = () => {

    //         const tabInterval = setInterval(() => {
    //             // Set the value of the state after n seconds (e.g., 5 seconds)
    //             // console.log('mt0', menutTitles[0].toLowerCase())
    //             setCurrentHoveredTab(menutTitles[Math.floor(Math.random() * (4 + 1))].toLowerCase());
    //         }, 3000);

    //         setHoverTabInterval(tabInterval);

    //         const tabTimeout = setTimeout(() => {
    //             clearInterval(tabInterval);
    //         }, 60000); // Stop after 30000 milliseconds (30 seconds)

    //         return () => {
    //             clearInterval(tabInterval);
    //             clearTimeout(tabTimeout); // clear timeout after ^ time
    //         };
    //     }

    //     const delayTimeout = setTimeout(startSettingTab, delay);

    //     // Clean up the delay timeout when the component unmounts
    //     return () => {
    //         clearTimeout(delayTimeout);
    //     };
    // }, []);

    // add autohide effect
    useEffect(() => {
        let autohide = document.querySelector('.autohide');
        // console.log('autohide');
        // add padding-top to body (if necessary)
        let navbar_height = document.querySelector('.navbar').offsetHeight;
        document.body.style.paddingTop = navbar_height + 'px';
        if (autohide) {
            var last_scroll_top = 0;
            window.addEventListener('scroll', function () {
                let scroll_top = window.scrollY;
                // scrolling up
                if (scroll_top < last_scroll_top) {
                    autohide.classList.remove('scrolled-down');
                    autohide.classList.add('scrolled-up');
                }
                // scrolling down
                else {
                    // if (scroll_top > last_scroll_top) {
                    autohide.classList.remove('scrolled-up');
                    autohide.classList.add('scrolled-down');
                }
                last_scroll_top = scroll_top;
                if (scroll_top == 0) {
                    autohide.classList.add('scrolled-top');
                }
                else {
                    autohide.classList.remove('scrolled-top')
                }
            });
        }
    }, []);


    const handleHover = () => {
        setLogoFontFamily(getRandomFont());
    };

    const handleLeave = () => {
        setLogoFontFamily('Gloock'); // Set it back to the default font when not hovered
    };

    const getRandomFont = () => {
        let randomIndex = Math.floor(Math.random() * randomFontFamilies.length);
        while (randomIndex == randomFontIndex) {
            randomIndex = Math.floor(Math.random() * randomFontFamilies.length);
        }
        setRandomFontIndex(randomIndex);
        return randomFontFamilies[randomIndex];
    };

    const handleMobileMenuClick = () => {
        setMenuVisible(!menuVisible)
    };

    return (
        <>
            {/* <Router> */}
            <nav className="autohide navbar nav-div scrolled-top">
                {/* mobile menu burger (position to left of logo) */}
                {isMobile ?
                    <div
                        className="mobile-menu-burger-div"
                    >
                        <IconButton
                            aria-label="menu"
                            size="large"
                            onClick={handleMobileMenuClick}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div> : <></>}
                {/* TPP logo */}
                <div className='nav-brand-div'>
                    <a className="nav-brand-link"
                        href="/"
                    >
                        <div className="brand-text-div">
                            <p
                                className="brand-text"
                                style={{ fontFamily: logoFontFamily }}
                            // onMouseOver={handleHover}
                            // onMouseLeave={handleLeave}
                            >The Pig Pencil</p>
                        </div>
                        <div className='brand-img-div'>
                            <img
                                className="brand-img"
                                src="/img/pigpencil.png"
                            />
                        </div>
                    </a>
                </div>
                {/* non-toggleabble desktop menu */}
                {isMobile ? <></> :
                    <div className="nav-menu-div">
                        <hoveredTabContext.Provider
                            value={{
                                currentHoveredTab,
                                setCurrentHoveredTab,
                                setUserHovered,
                                location,
                                menuVisible,
                                setMenuVisible
                            }}>
                            <ul className="nav-menu-list">
                                {menuItems.map((menu, index) => {
                                    return <MenuItems items={menu} />;
                                })}
                            </ul>
                        </hoveredTabContext.Provider>
                    </div>
                }
                {isMobile ? <></> : <div className="nav-subscribe-div">
                    <Link to="/subscribe">
                        <input id="subscribe-btn" type="button" value="Subscribe"></input>
                    </Link>
                </div>}
                {/* toggleable mobile menu */}

                {menuVisible ?
                    <div className="nav-menu-div-mobile">
                        <hoveredTabContext.Provider
                            value={{
                                currentHoveredTab,
                                setCurrentHoveredTab,
                                setUserHovered
                            }}>
                            <ul className="nav-menu-list">
                                {menuItems.map((menu, index) => {
                                    return <MenuItems items={menu} />;
                                })}
                            </ul>
                        </hoveredTabContext.Provider>
                        {/* place subscribe button in dropdown for mobile */}
                        {isMobile ? <div className="nav-subscribe-div">
                            <Link to="/subscribe">
                                <input id="subscribe-btn" type="button" value="Subscribe"></input>
                            </Link>
                        </div> : <></>}
                    </div> : <></>
                }
            </nav>

            <Routes>
                <Route exact path="/" element={
                    <hoveredTabContext.Provider
                        value={{
                            currentHoveredTab,
                            setCurrentHoveredTab
                        }}>
                        <Home />
                    </hoveredTabContext.Provider>
                } className="nav-item">

                </Route>

                <Route exact path="/art" element={<Art />}></Route>

                <Route exact path="/data" element={<Data />}></Route>

                <Route exact path="/cheatsheets" element={<Cheatsheets />}></Route>
                <Route exact path="/tutorials" element={<Tutorials />}></Route>

                <Route exact path="/writing" element={<Writing />}></Route>

                <Route exact path="/projects" element={<Projects />}></Route>

                <Route exact path="/me" element={<Me />}></Route>
                <Route exact path="/collaborators" element={<Collaborators />}></Route>
                {/* <Route exact path="/portfolio" element={<Portfolio />}></Route> */}

                <Route exact path="/subscribe" element={<Subscribe />}></Route>

                {/* for specific art, data, and writing pages */}
                {
                    artPosts.map(category => {
                        return <Route key={category} exact path={"/art/" + category.FileName} element={<ArtPage folder={category.FileName} />}></Route>
                    })
                }

                {
                    dataPosts.map(post => {
                        // let blogPostStyle;

                        // if (post.PageStyle == 1) {
                        // blogPostStyle = <DataPage fileName={post.FileName} fileNameNoExt={post.FileNameNoExt} title={post.Title} author={post.Author} date={post.Date} caption={post.Caption} />
                        let blogPostStyle = <DataPage section={'data'} postData={post} />
                        // }
                        // else if (post.PageStyle == 2) {
                        //     blogPostStyle = <PhotoEssay fileName={post.FileName} componentName={get_component_name(post.FileName)} title={post.Title} author={post.Author} date={post.Date} />
                        // }
                        // let blogPostStyle = <ArticleTemplate section={'data'} postData={post} />

                        return <Route key={post} exact path={"/data/" + post.FileNameNoExt} element={blogPostStyle}></Route>
                    })
                }

                {
                    writingPosts.map(post => {
                        // can allow for diff. writing templates later
                        let blogPostStyle = <WritingPage section={'writing'} postData={post} />
                        return <Route key={post} exact path={"/writing/" + post.FileNameNoExt} element={blogPostStyle}></Route>
                    })
                }
                {
                    cheatSheetPosts.map(post => {
                        return <Route key={post} exact path={"/cheatsheets/" + post.FileNameNoExt} element={<CheatsheetPage fileName={post.FileName} fileNameNoExt={post.FileNameNoExt} subFolder={post.Subfolder} title={post.Title} author={post.Author} date={post.Date} />}></Route>
                    })
                }
                {
                    tutorialPosts.map(post => {
                        return <Route key={post} exact path={"/tutorials/" + post.FileNameNoExt} element={<TutorialPage fileName={post.FileName} fileNameNoExt={post.FileNameNoExt} subFolder={post.Subfolder} title={post.Title} author={post.Author} date={post.Date} />}></Route>
                    })
                }
                {
                    projectsPosts.map(post => {
                        let blogPostStyle = <WritingPage section={'projects'} postData={post} />
                        return <Route key={post} exact path={"/projects/" + post.FileNameNoExt} element={blogPostStyle}></Route>
                    })
                }
            </Routes>
            {/* </Router > */}

        </>
    )
}

export default NavBar;