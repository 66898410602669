import { useState, useRef, useEffect, useContext } from "react";
import { Link, useLocation } from 'react-router-dom';

import { hoveredTabContext } from "./NavBar.js";
import Dropdown from './Dropdown.js';
import DropdownMenuItem from "./DropdownMenuItem";

import './MenuItem.css';

const MenuItems = ({ items }) => {
    const location = useLocation();
    const { currentHoveredTab, setCurrentHoveredTab, setUserHovered } = useContext(hoveredTabContext);

    return (
        <li
            className={`menu-item ${items.submenu ? 'menu-item-dropdown' : ''}`}
            //  ${currentHoveredTab == items.title.toLowerCase() ? 'menu-item-hovered': ''}`}
            onMouseEnter={() => {
                // only trigger setting on home page
                if (location.pathname == '/') {
                    setCurrentHoveredTab(items.title.toLowerCase())
                    setUserHovered(true);
                }
            }
            }
        >
            {/* add submenu if submenu property exists, otherwise, just link */}
            {items.submenu ? (
                <DropdownMenuItem title={items.title} submenu={items.submenu} />
            ) : (
                <Link to={items.url}>{items.title}</Link>
            )}
        </li>
    );
};

export default MenuItems;