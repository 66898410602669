import React, { useEffect, useState } from 'react';
import './Scrollspy.css';


export function ScrollspyHeader({ level = 2, isInline = false }) {
    // default h2
    const Tag = `h${level}`;
    if (isInline) {
        return <Tag className="presHeader presHeaderInline"></Tag>
    }
    return (
        <Tag className="presHeader"></Tag>
        // <Tag className="presHeader">{
        //     nestedImage ? nestedImage : 'Default Text'
        // }</Tag>
    );
}


const scrollToHeader = (headerID) => {
    // console.log('scroll to', headerID);
    const headerElement = document.querySelector('#section' + headerID);

    if (headerElement) {
        // Calculate the desired scroll position with some space above
        const scrollPosition = headerElement.offsetTop - 70; // Adjust the value as needed

        // Scroll to the calculated position
        window.scrollTo({
            top: scrollPosition,
            //   behavior: 'smooth', // Optional: Add smooth scrolling animation
        });
    }
};

export default function Scrollspy({ sectionTitles = ['section1', 'section2'] }) {
    // const headerElements = document.querySelectorAll('.presHeader');

    // MOVED UPDATE HEADER LOGIC TO INDIVIDUAL PAGE TEMPLATES
    // update header elements when section titles change
    // useEffect(() => {
    //     const headerElements = document.querySelectorAll('.presHeader');
    //     // console.log('page loaded',headerElements);
    //     headerElements.forEach(function (elt, i) {
    //         elt.innerText = sectionTitles[i];
    //         elt.setAttribute('id', 'section' + String(i));
    //     });
    // }, [sectionTitles]);

    return (
        <div className='containerScrollspy'>
            {/* <nav id="articleScrollspy" className="navbar scrollspyNavbar"> */}
            <nav id="articleScrollspy" className="scrollspyNavbar">
                {
                    sectionTitles.map((s, i) => {
                        return (
                            <a key={i}
                                className="scrollspyLink"
                                onClick={() => { scrollToHeader(i) }}
                            // href={'#section' + String(i)}
                            >{s}</a>
                        )
                    })

                }
            </nav >
        </div>
    )
}
