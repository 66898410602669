import React, { useState, useEffect, useRef, createContext } from 'react'
import Dropdown from './Dropdown';

export const DropdownContext = createContext({});

export default function DropdownMenuItem({ title = 'dropdown', submenu = ['a', 'b'] }) {
    // const [iconRotated, setIconRotated] = useState(false);
    const [dropdownVisibility, setDropdownVisibility] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            // if dropdown doesn't contain the click (i.e. outside the dropdown, set vis to false)
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisibility(false);
            }
        }
        // adds listener to document
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <DropdownContext.Provider
            value={{
                dropdownVisibility,
                setDropdownVisibility
            }}
        >
            <span
                ref={dropdownRef}
                className="nav-dropdown-span"
            >
                {/* submenu title */}
                <span
                    onClick={() => setDropdownVisibility(!dropdownVisibility)}
                >
                    {title}
                    <img className={`caret-icon ${dropdownVisibility ? 'rotated-icon' : ''}`} src='/img/icons/caret-right.png'></img>
                </span>
                {/* submenu */}
                {dropdownVisibility && (<Dropdown
                    submenus={submenu}
                    // dropdown={dropdownVisibility}
                />)}
            </span>
        </DropdownContext.Provider>
    )
}
